.toggle {
  @apply inline-flex items-center gap-1 sm:gap-2 h-[30px] mr-3 sm:mr-5;
  @apply px-2 py-1.5 rounded-md bg-white/[0.08] transition-all;

  &[aria-expanded="true"],
  &:hover,
  &:focus {
    @apply bg-white/[0.16];
  }
  // bg-white/[0.16]
}

.scrollable {
  @apply mb-0.5;
}

.item { @apply flex justify-between items-center gap-2; }
.group { @apply max-w-[80%]; }
.title { @apply text-sm text-white; }
.link { 
  @apply text-xs text-white/[0.3]; 
  @apply overflow-hidden text-ellipsis whitespace-nowrap;
}
.copyIcon { @apply w-4 text-white; }

.itemAction { @apply flex items-center gap-2 justify-start; }