/* PayoutsWallet styles */
.root {

}
.action {
  color: #FF5F01;
}
.list {
  background: rgba(255, 255, 255, 0.08);
  border-radius: 10px;

  &.isEmpty {
    background: rgba(255, 255, 255, 0.04);
    color: rgba(255, 255, 255, 0.3);

    @apply flex items-center justify-center text-xs py-6;
  }
}
.addressCopy {
  @apply hidden;
}
.address {
  color: rgba(255, 255, 255, 0.3);

  &:hover {
    .addressText {
      @apply hidden;
    }
    .addressCopy {
      @apply block;

      color: #FF5F01;
    }
  }
}