///* Button styles */

.button {
  @apply inline-block bg-transparent box-border border-none relative no-underline rounded-md;
  @apply cursor-pointer whitespace-normal appearance-none select-none;
  @apply text-white;

  -webkit-touch-callout: none;
  outline: none;
  font-size: inherit;
  font-weight: inherit;

  &.secondary {
    background-color: rgba(255, 255, 255, 0.08);
    &:hover {
      box-shadow: 0 0 1px 1px #64D2FF;
    }

    &:focus,
    &.isActive {
      background-color: rgba(255, 255, 255, 0.16);
      box-shadow: none;
    }
    &.disabled {
      color: rgba(255, 255, 255, 0.18);
      background-color: rgba(255, 255, 255, 0.08);
      box-shadow: none;
    }

    &.isLoading {
      box-shadow: none;
      background-color: rgba(255, 255, 255, 0.08);
    }
  }

  &.primary {
    background-image: linear-gradient(180deg, #F47D02 0%, #FB6101 100%);

    &:hover {
      box-shadow: 0 0 1px 1px #64D2FF;
    }

    &:focus,
    &.isActive {
      background-image: linear-gradient(0deg, rgba(0, 0, 24, 0.1), rgba(0, 0, 24, 0.1)), linear-gradient(180deg, #F47D02 0%, #FB6101 100%);
      box-shadow: none;
    }

    &.isLoading {
      box-shadow: none;
      background-image: linear-gradient(180deg, #F47D02 0%, #FB6101 100%);
    }

    &.disabled {
      color: rgba(255, 255, 255, 0.18);
      background-color: rgba(255, 255, 255, 0.08);
      background-image: none;
      box-shadow: none;
    }
  }
}

.inner {
  @apply flex justify-center items-center text-center px-3 font-sans font-semibold;
  @apply box-border border border-solid border-transparent rounded-md transition text-xs relative;
  &.xs {
    min-height: 24px;
  }
  &.sm {
    min-height: 30px;
  }
  &.md {
    min-height: 40px;
  }
  &.isIconBtn {
    & .prefix {
      @apply mr-0;
    }
    & .suffix {
      @apply ml-0;
    }
    &.sm {
      @apply px-[3px]
    }
    &.xs {
      @apply px-[1px]
    }
  }
}

.prefix {
  @apply block mr-[6px];

  & > figure {
    @apply block;
  }
}

.suffix {
  @apply block ml-[6px];
  & > figure {
    @apply block;
  }
}

.prefixIcon,
.iconRight {
  @apply shrink-0;
}

.loadingDots {
  @apply flex items-center justify-center;

  height: 1rem;
}


