/* EditableDescription styles */
.root {
  @apply h-[24px] relative flex items-center w-full;
}

.input {
  @apply text-white rounded-md px-[10px] text-xs leading-3 h-full;
  @apply h-[24px] w-full box-border border border-solid border-transparent outline-none bg-transparent align-middle;

  border-color: rgba(255, 255, 255, 0.1);

  &::selection {
    background-color: #F16528;
  }
}