/* DropdownMenu styles */
.button {
  @apply h-5 w-5 rounded-md p-1 flex items-center bg-transparent transition-all duration-200;

  min-width: 24px;
  min-height: 24px;

  .icon {
    @apply text-2xl text-white;
  }

  &:hover {
    background: rgba(255, 255, 255, 0.08);
  }

  &.expand {
    background: rgba(255, 255, 255, 0.16);
  }
}

.list {
  @apply mt-1 p-1 rounded-md backdrop-blur-lg;

  background: rgba(255, 255, 255, 0.08);
  border: 1px solid rgba(255, 255, 255, 0.2);
  box-shadow: 0 25px 50px -12px rgba(0, 0, 0, 0.45);
}

.item {
  @apply px-3 pt-[9px] pb-[9px] cursor-pointer text-xs leading-3 rounded-sm flex;

  &:hover {
    background: linear-gradient(180deg, #F47D02 0%, #FB6101 100%);
  }

  &:focus, &:active {
    background-image: linear-gradient(0deg, rgba(0, 0, 24, 0.1), rgba(0, 0, 24, 0.1)), linear-gradient(180deg, #F47D02 0%, #FB6101 100%);
  }
}

.item[data-selected] {
  background: linear-gradient(180deg, #F47D02 0%, #FB6101 100%);
}

@keyframes slide-down {
  0% {
    opacity: 0;
    transform: translateY(-10px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

.list[data-reach-menu-popover],
.list[data-reach-menu-list],
.list[data-reach-menu-items] {
  animation: slide-down 0.2s ease;
}