/* CookieBanner styles */
.root {
  @apply fixed bottom-4 left-0 right-0 z-[102];
  @apply max-w-[441px] mx-auto border border-solid rounded-2xl backdrop-blur-3xl text-white p-4 sm:p-6;

  background: rgba(255, 255, 255, 0.08);
  border-color: rgba(255, 255, 255, 0.1);
}

.link {
  @apply text-[#ff5f01];

  &:hover {
    @apply underline;
  }
}