/* Avatar styles */
.avatar {
  @apply appearance-none border-none;
  @apply rounded-full text-[#16191D] inline-flex items-center justify-center font-medium backdrop-blur;

  background: linear-gradient(180deg, #FFFFFF 0%, rgba(255, 255, 255, 0.65) 100%);

  &:hover,
  &:focus {
    background: linear-gradient(180deg, #FFFFFF 0%, rgba(255, 255, 255, 0.65) 100%);
  }

  &.sm {
    @apply h-[26px] w-[26px] text-sm;
  }
  &.xl {
    @apply h-[60px] w-[60px] text-2xl;
  }
}
