/* FieldWrapper styles */
.root {
  @apply relative inline-block font-sans;

  &.full {
    @apply block w-full;
  }
}
.label {

  @apply block text-xs leading-4 mb-1 font-medium;

  .infoIcon {
    color: #37DBFF;

    @apply inline-block align-middle ml-2 text-sm;
  }
}
.labelText {
  color: rgba(255, 255, 255, 0.6);

  @apply inline-block align-middle text-xs leading-4; // text-md
}
.hint {
  color: rgba(255, 255, 255, 0.3);

  @apply text-2xl mt-1 block text-xs;
}
.error {
  color: #FF2420;

  @apply text-xs mt-1 text-red-700 block;
}
