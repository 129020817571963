/* Modal styles */
.overlay {
  @apply fixed top-0 left-0 bottom-0 right-0 overflow-auto z-[60];

  @apply flex justify-center items-baseline md:items-center;

  &:before {
    background: linear-gradient(180deg, #000018 24.15%, #35261D 100%), #FFFFFF;
    @apply content-[''] fixed top-0 left-0 bottom-0 right-0 overflow-auto z-20 opacity-80;
  }
}

.content {
  @apply will-change-transform relative z-[70] max-w-full sm:w-[420px] bg-white/[0.04] backdrop-blur-lg;
  @apply border border-solid border-white/[0.1] rounded-2xl;
  @apply w-full max-w-[420px] m-3 p-6;
}
