/* Select styles */
.inputWrap {
  @apply relative;

  &.full {
    .input {
      @apply w-full;
    }
  }

  &.hasError {
    :global {
      .ui-select .ui-select__control {
        @apply border-amber-800;
      }
    }
  }

  :global {
    .ui-select {
      &.ui-select__sm .ui-select__control {
        min-height: 22px;
      }
      &.ui-select__md .ui-select__control {
        min-height: 30px;
      }

      // Default
      &.ui-select__default .ui-select__control {
        @apply h-[30px] rounded-md py-1 pl-2 pr-1 border border-solid border-transparent flex items-center bg-transparent transition;

        min-height: 30px;
        min-width: 150px;
        box-shadow: none;
        background-color: rgba(255, 255, 255, 0.08);

        &:hover {
          & .ui-select__placeholder, & .ui-select__indicator {
            color: rgba(255, 255, 255, 0.7);
          }

          border-color: #64D2FF;
        }
        
        &.ui-select__control--control-is-focused,
        &.ui-select__control--menu-is-open,
        &:focus {
          border-radius: 6px;
          background-image: linear-gradient(#2A2635, #2A2635), linear-gradient(#52EDC7, #5AC8FB);
          background-origin: border-box;
          background-clip: padding-box, border-box;
          border-color: transparent;
        }

        & .ui-select__indicator {
          @apply p-0;

          transform: rotate(0deg);
          transition: transform 0.3s ease-in;
        }
      }

      &.ui-select__default .ui-select__control--menu-is-open {
        @apply text-white;
        & .ui-select__indicator {
          @apply text-white #{!important};

          transform: rotate(-180deg);
        }
      }

      &.ui-select__default .ui-select__multi-value {
        @apply mt-0 mb-0 flex items-center;

        padding: 2px 6px;
        border-radius: 2px;
        background-color: rgba(255, 255, 255, 0.08);
        border-color: rgba(255, 255, 255, 0.9);
      }

      &.ui-select__default .ui-select__multi-value .ui-select__multi-value__label {
        @apply text-white text-xs leading-3 font-medium p-0;
      }

      &.ui-select__default .ui-select__multi-value .ui-select__multi-value__remove {
        @apply text-white p-0 bg-transparent;

        margin-left: 6px;
        font-size: 10px;
      }

      // Basic
      &.ui-select__basic .ui-select__control {
        @apply rounded-md p-0 border border-solid border-transparent flex items-center bg-transparent transition cursor-pointer;

        min-height: auto;
        box-shadow: none;

        &:hover {
          & .ui-select__placeholder, & .ui-select__indicator {
            color: rgba(255, 255, 255, 0.7);
          }
        }

        & .ui-select__indicator {
          @apply p-0;

          margin-top: -1px;
        }
      }

      &.ui-select__basic .ui-select__control--menu-is-open {
        @apply text-white;

        .ui-select__indicator {
          @apply text-white;
        }
      }

      &.ui-select__basic .ui-select__multi-value {
        @apply mt-0 mb-0 flex items-center;

        padding: 2px 6px;
        border-radius: 2px;
        background-color: rgba(255, 255, 255, 0.08);
        border-color: rgba(255, 255, 255, 0.9);
      }

      &.ui-select__basic .ui-select__multi-value .ui-select__multi-value__label {
        @apply text-white text-xs leading-3 font-medium p-0;
      }

      &.ui-select__basic .ui-select__multi-value .ui-select__multi-value__remove {
        @apply text-white p-0 bg-transparent;

        margin-left: 6px;
        font-size: 10px;
      }

      &.ui-select__basic .ui-select__menu {
        @apply top-[0] -mt-1 w-auto;
      }

      & .ui-select__value-container {
        @apply flex items-center text-white text-xs leading-3 p-0;
      }

      & .ui-select__input {
        color: white !important;
      }

      & .ui-select__placeholder {
        @apply m-0;
        color: rgba(255, 255, 255, 0.6);
      }

      & .ui-select__single-value {
        @apply text-white leading-5;
      }

      & .ui-select__indicator {
        @apply transition p-0 pl-[4px];

        color: rgba(255, 255, 255, 0.6);
      }

      & .ui-select__menu {
        @apply p-1 rounded-md top-[90%];

        background: #2A2635;
        border: 1px solid rgba(255, 255, 255, 0.2);
        box-shadow: 0 25px 50px -12px rgba(0, 0, 0, 0.45);

      }

      & .ui-select__menu-list {
        @apply p-0 m-0;
      }

      & .ui-select__option {
        @apply cursor-pointer flex items-center px-2 py-[9px] mt-1 first:mt-0 text-xs leading-3;

        font-size: 12px !important;
        border-radius: 2px;

        &:active {
          background-image: linear-gradient(0deg, rgba(0, 0, 24, 0.1), rgba(0, 0, 24, 0.1)), linear-gradient(180deg, #F47D02 0%, #FB6101 100%);
        }
      }

      & .ui-select__option--is-focused, & .ui-select__option--is-selected {
        background-image: linear-gradient(180deg, #F47D02 0%, #FB6101 100%);
      }

      &.ui-select--is-disabled {
        & .ui-select__control {
          @apply border-transparent cursor-not-allowed;
        }
        & .ui-select__indicator {
          color: rgba(255, 255, 255, 0.18);
        }

        & .ui-select__placeholder, & .ui-select__single-value {
          color: rgba(255, 255, 255, 0.18);
        }
      }
    }
  }
}
