/* Disclosure styles */
.panel {
  animation: slide-down 0.3s ease;
}

@keyframes slide-down {
  0% {
    opacity: 0;
    transform: translateY(-10px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}