/* LoaderDots styles */
.root {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 36px;
}

.dot {
  display: block;
  height: 7px;
  width: 7px;
  min-height: 7px;
  min-width: 7px;
  border-radius: 50%;
  background-color: #ffffff;
  opacity: 0.2;
  margin-left: 5px;
  animation: blink 0.6s infinite linear 0s;

  &:first-child {
    margin-left: 0;
  }

  .isDark & {
    background-color: #818e9c;
  }
}

.dot:nth-of-type(2) {
  animation-delay: 0.2s;
}

.dot:nth-of-type(3) {
  animation-delay: 0.4s;
}

@keyframes blink {
  from { opacity: 0.2; }
  40% { opacity: 0.2; }
  80% { opacity: 1; }
  to { opacity: 0.2; }
}
