@font-face {
  font-family: 'Gilroy';
  src: url('/fonts/Gilroy/gilroy-thin-webfont.woff2') format('woff2'),
      url('/fonts/Gilroy/gilroy-thin-webfont.woff') format('woff');
  font-weight: 100;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Gilroy';
  src: url('/fonts/Gilroy/gilroy-light-webfont.woff2') format('woff2'),
      url('/fonts/Gilroy/gilroy-light-webfont.woff') format('woff');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Gilroy';
  src: url('/fonts/Gilroy/gilroy-regular-webfont.woff2') format('woff2'),
      url('/fonts/Gilroy/gilroy-regular-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Gilroy';
  src: url('/fonts/Gilroy/gilroy-medium-webfont.woff2') format('woff2'),
      url('/fonts/Gilroy/gilroy-medium-webfont.woff') format('woff');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Gilroy';
  src: url('/fonts/Gilroy/gilroy-semibold-webfont.woff2') format('woff2'),
      url('/fonts/Gilroy/gilroy-semibold-webfont.woff') format('woff');
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Gilroy';
  src: url('/fonts/Gilroy/gilroy-bold-webfont.woff2') format('woff2'),
      url('/fonts/Gilroy/gilroy-bold-webfont.woff') format('woff');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Gilroy';
  src: url('/fonts/Gilroy/gilroy-extrabold-webfont.woff2') format('woff2'),
      url('/fonts/Gilroy/gilroy-extrabold-webfont.woff') format('woff');
  font-weight: 800;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Gilroy';
  src: url('/fonts/Gilroy/gilroy-heavy-webfont.woff2') format('woff2'),
      url('/fonts/Gilroy/gilroy-heavy-webfont.woff') format('woff');
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}
