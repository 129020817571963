/* Tooltip styles */
.root {
  @apply relative z-50 text-left inline-block;
}

.tooltip {
  @apply text-left px-3 py-2 max-w-[260px] z-[91] w-auto border border-solid rounded-md backdrop-blur-md;

  background-color: rgba(255, 255, 255, 0.08);
  border-color: rgba(255, 255, 255, 0.2);
  box-shadow: 0 25px 50px -12px rgba(0, 0, 0, 0.45);

  &::after {
    content: '';
  }
}

.title {
  @apply text-2xl font-semibold mb-4;
}

.content {
  color: var(--hos-color-primary-grey-08);

  @apply text-xs text-white break-words;

  b {
    color: var(--hos-color-gray-094);
  }
}
