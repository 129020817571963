$border: 1px;

/* AccountListItem styles */
.root {
  @apply outline-none;
  @apply px-4 py-4 rounded-xl flex gap-3 justify-between items-center appearance-none border-none w-full;
  @apply bg-white/[0.08];
  color: #fff;

  position: relative;
  border: solid $border transparent;
  background-origin: border-box;
  background-clip: padding-box, border-box;

  transition: background-color 0.3s ease-in-out;

  &:not(.pending) {
    @apply cursor-pointer;
  }
  
  &.pending {
    @apply cursor-wait;
  }

  &:hover:not(.active),
  &:focus:not(.active) {
    background-color: #41291d;
  }

  &.active {
    background-color: #4a2612;

    &::before,
    &::after {
      content: '';
      position: absolute;
      bottom: 0;
      top: 0;
      left: 0;
      right: 0;
      z-index: -1;
      margin: -$border;
      border-radius: inherit;
    }

    &::before {
      background-image: linear-gradient(191deg, #FFE806, #FE5400);
    }
  }
  & + & {
    @apply mt-2;
  }
    
} 

.avatar {
  background: rgba(255, 255, 255, 0.04);
  font-size: 28px;
  height: 38px;
  width: 38px;
}
.texts {
  max-width: 50%;
}
.description {
  @apply text-white/[0.3];
}
.hashrate {
  @apply whitespace-nowrap text-white/[0.6];
}
