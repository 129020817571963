/* List styles */
.list {
  @apply rounded-[10px] overflow-x-hidden;
}

.scrollable {
  @apply overflow-y-auto;

  // hide scroll
  &::-webkit-scrollbar {
    display: none;
  }
  scrollbar-width: none;  

  .notRoundLast .item:last-child .itemLink {
    @apply rounded-b-none;
  }
}

.item {
  &:first-child .itemLink {
    @apply rounded-t-[10px];
  }
  &:last-child .itemLink.roundLast {
    @apply rounded-b-[10px];
  }

  &:not(:last-child) {
    &.xs {
      @apply mb-[1px];
    }
    &.sm {
      @apply mb-0.5;
    }
  } 
}
.itemLink {
  @apply p-3 outline-none w-full flex justify-between items-center;

  &.primary {
    @apply bg-white/[0.04];
  }
  &.secondary {
    @apply bg-white/[0.08];
  }
  &.tertiary {
    @apply bg-white/[0.16];
  }

  &.clickable {
    @apply cursor-pointer;
    &.primary:hover {
      @apply bg-white/[0.08];
    }
  
    &.secondary:hover {
      @apply bg-white/[0.12];
    }

    &.tertiary:hover {
      @apply bg-white/[0.20];
    }

    &:focus-visible {
      @apply bg-[#f47d02]/[0.3];
    }
  }

  &[data-highlighted],
  &.active {
    @apply bg-[#f47d02]/[0.3];
  }
}
