/* SwipeList styles */
.actionBtn {
  @apply inline-flex items-center appearance-none bg-transparent text-[#FF5F01] border-none;
  @apply text-[15px] outline-none px-1 py-1;
}

.list {
  @apply relative;
}

.page {
  @apply absolute h-full w-full;
}