/* TextLoader styles */
.root {
  @apply inline-flex rounded-md overflow-hidden relative w-full;

  background-color: rgba(255, 255, 255, 0.04);
  z-index: 1; /* Necessary for overflow: hidden to work correctly in Safari */
}

.root::after {
  @apply absolute top-0 left-0 right-0 h-full block bg-no-repeat;

  content: ' ';
  background-image: linear-gradient(
                  90deg,
                  rgba(255, 255, 255, 0.04),
                  rgba(255, 255, 255, 0.16),
                  rgba(255, 255, 255, 0.04)
  );
  transform: translateX(-100%);

  animation-name: react-loading-skeleton;
  animation-direction: normal;
  animation-duration: 1.5s;
  animation-timing-function: ease-in-out;
  animation-iteration-count: infinite;
}

@keyframes react-loading-skeleton {
  100% {
    transform: translateX(100%);
  }
}
