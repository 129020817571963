/* Drawer styles */
.overlay {
  @apply fixed top-0 left-0 bottom-0 right-0 overflow-auto z-[60] p-4;
  @apply bg-[#000018]/[0.8];
}

.content {
  @apply fixed p-4 border border-white/[0.1];
  @apply z-[10000000000]; // override Intercom
  @apply bg-white/[0.04] backdrop-blur-[40px];

  &.bottom {
    @apply bottom-0 left-0 right-0 rounded-t-xl;
  }
}