/* PayoutsCoinsItem styles */
.root {
  background: rgba(255, 255, 255, 0.08);
  transition: all 0.3s ease-in;

  &:last-child {
    border-radius: 0 0 10px 10px;
  }

  &.active {
    background: rgba(244, 125, 2, 0.18);
  }

  &:hover:not(.active) {
    background: rgba(244, 125, 2, 0.09);
  }
}