/* SubaccountsInactive styles */
.root {
  @apply overflow-hidden;
}

.list {
  @apply overflow-y-auto max-h-[368px];
  @apply -mr-5 pr-5; // hide scrollbar
  border-top: 1px solid transparent;
  border-bottom: 1px solid transparent;
  transition: all 0.3s ease-in;
}

.isScroll {
  border-top-color: rgba(255, 255, 255, 0.1);
}

.isScrollEnd {
  border-bottom-color: rgba(255, 255, 255, 0.1);
}

.titleHover {
  &:hover {
    color: rgba(255, 255, 255, 0.7);
  }
}

.arrowIcon {
  transform: rotate(-180deg);
  transition: transform 0.3s ease-in;

  &.open {
    transform: rotate(0deg);
  }
}