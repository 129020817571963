/* RowBlock styles */
.list {
  .item {
    @apply rounded-none;

    &:first-child { @apply rounded-t-[10px]; }
    &:last-child { @apply rounded-b-[10px]; }
    &:not(:last-child) { @apply mb-[1px]; }
  }
}

.item {
  @apply rounded-[10px] bg-white/[0.08];

  > :nth-child(1) {
    @apply text-left pr-3 basis-1/3;
  }
  > :nth-child(2) {
    @apply text-right basis-2/3 min-w-0;
  }
}
