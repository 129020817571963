.toggler {
  @apply text-white/[0.5] flex items-center outline-none;

  &:hover,
  &:focus {
    @apply text-[#FF9500];
  }
}

.navList {
  @apply bg-white/[0.04] border border-white/[0.1];
}
