@tailwind base;
@tailwind components;
@tailwind utilities;

html, body {
  @apply h-full;
}

input {
  color-scheme: dark;
}

body {
  background: linear-gradient(180deg, #000018 24.15%, #35261D 100%), #35261D;
  background-attachment: fixed;
  @apply bg-no-repeat text-white;
}

@import 'fonts';
@import 'select';

// Input number arrows
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
input[type=number] {
  -moz-appearance: textfield; /* Firefox */
}
//\ Input number arrows

.button-base { @apply appearance-none border-none cursor-pointer; }

div[data-radix-popper-content-wrapper] {
  z-index: 60!important;
}
.ui-select__menu-portal {
  z-index: 100!important;
}