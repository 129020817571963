// portal react select menu styles

.ui-select__menu-portal {
  .ui-select__menu {
    @apply py-1 rounded-md backdrop-blur-lg;

    background-color: rgba(255, 255, 255, 0.08);
    border: 1px solid rgba(255, 255, 255, 0.2);
    box-shadow: 0 25px 50px -12px rgba(0, 0, 0, 0.45);
  }

  .ui-select__menu-list {
    @apply p-0 m-0;
    // hide scrollbar
    &::-webkit-scrollbar {
      display: none;
    }
    scrollbar-width: none;  
  }

  .ui-select__option {
    @apply cursor-pointer flex items-center px-3 py-2.5 mt-[1px] first:mt-0 text-xs leading-3;
  }

  
  .ui-select__option--is-focused {
    @apply bg-white/[0.08];
  }
  .ui-select__option:active,
  .ui-select__option--is-selected {
    @apply bg-[#F47D02]/[0.3];
  }

  .ui-select__option-with-checkmark {
    @apply pl-9 relative;
  }

  .checkmark-icon {
    @apply w-4 absolute top-1/2 left-3 text-[#FF9500] text-center opacity-0 transition-all duration-300;

    transform: translateY(-50%);
  }
  .ui-select__option--is-selected .checkmark-icon {
    @apply opacity-100;
  }
}

.ui-select__menu-notice {
  @apply text-xs leading-3;
}