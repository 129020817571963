/* SkeletonListItem styles */
.root {
  @apply relative px-4 py-4 rounded-xl flex gap-3 justify-center items-center appearance-none w-full;
  background: rgba(255, 255, 255, 0.08);


  //height: 74px;
  background-origin: border-box;
  background-clip: padding-box, border-box;

  & + & {
    @apply mt-2;
  }
}