/* PayoutsStats styles */
.root {

}
  .top {
    background: rgba(255, 255, 255, 0.08);
    border-radius: 10px 10px 0px 0px;
  }
  .bottom {
    background: rgba(255, 255, 255, 0.08);
    border-radius: 0px 0px 10px 10px;
  }
  .dimmed {
    color: rgba(255, 255, 255, 0.6);
  }
  .muted {
    color: rgba(255, 255, 255, 0.3);
  }

  .estValue {
    @apply w-[77%] #{!important};
  }