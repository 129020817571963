/* InputCode styles */
.input {
  @apply border border-solid border-transparent;

  background-color: rgba(255, 255, 255, 0.08);
  -webkit-appearance: none;
  appearance: none;
  -moz-appearance: textfield;

  &:hover {
    color: rgba(255, 255, 255, 0.7);
    border-color: #64D2FF;
  }

  &:focus {
    @apply text-white;

    border-radius: 6px;
    background-image: linear-gradient(#2A2635, #2A2635),
    linear-gradient(#52EDC7, #5AC8FB);
    background-origin: border-box;
    background-clip: padding-box, border-box;
    border-color: transparent;
  }
}
