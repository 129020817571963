/* Accounts styles */
.row {
  @apply flex w-full sm:max-w-[994px] xl:max-w-[1036px] mx-auto;
  @apply sm:overflow-x-auto;

  transform: translate(0, 0);
  transition: transform .5s ease;

  // hide scrollbar
  &::-webkit-scrollbar {
    display: none;
  }
  -ms-overflow-style: none;  /* Internet Explorer 10+ */
  scrollbar-width: none;  

  @media only screen and (max-width: 640px) and (orientation: portrait) {
    &.main { transform: translate(0, 0); }
    &.details { transform: translate(-100%, 0); }
    &.payouts { transform: translate(-200%, 0); }
  } 
}
.col {
  &:first-child {
    @apply min-w-full sm:min-w-[316px] sm:max-w-[316px];
  }
  @apply min-w-full sm:min-w-[340px] xl:min-w-[360px] px-5 md:px-0;
  @apply sm:max-w-[320px] xl:max-w-[360px];
  @apply md:px-5;
}
