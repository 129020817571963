/* PayoutsCoinsList styles */
.root {
  &:not(.open) .toggle {
    &:hover {
      background: rgba(244, 125, 2, 0.15);
    }
  }
}
  .toggle {
    background: rgba(255, 255, 255, 0.08);
    border-radius: 10px;
    transition: all 0.3s ease-in;

    @apply cursor-pointer appearance-none border-none;

    > :nth-child(1) {
      @apply text-left pr-3 basis-1/3 min-w-0;
    }
    > :nth-child(2) {
      @apply text-right basis-2/3 min-w-0;
    }

    .open & {
      // background: rgba(244, 125, 2, 0.3);
      border-radius: 10px 10px 0 0;
    }
  }
  .arrowIcon {
    transform: rotate(-180deg);
    transition: transform 0.3s ease-in;

    .open & {
      transform: rotate(0deg);
    }
  }