/* Details styles */
.root {

}

.muted {
  color: rgba(255, 255, 255, 0.6);
}

.detailsRow {
  @apply mb-5;

  > :nth-child(1) {
    @apply basis-auto;
  }
  > :nth-child(2) {
    @apply basis-auto flex-grow #{!important};
  }
}